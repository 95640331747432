import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import "./chat-welcome-message.css";

export const ChatWelcomeMessage = () => {
  const location = useLocation();
  const [showBadge, setShowBadge] = useState(true);

  const handleClick = useCallback(() => {
    const chatButton = document.querySelector(
      ".chat-window-toggle"
    ) as HTMLElement;

    if (chatButton) {
      chatButton.click();
      setShowBadge(false);
    }
  }, []);

  if (
    !showBadge ||
    (!location.pathname.startsWith("/app/catalog") && location.pathname !== "/")
  )
    return <></>;

  return (
    <Box
      onClick={handleClick}
      sx={{
        position: "fixed",
        bottom: "16px",
        right: "16px",
        width: "64px",
        height: "64px",
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderRadius: "64px",
        zIndex: 99999,
        cursor: "pointer",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "#f44336",
            width: "20px",
            height: "20px",
            borderRadius: "20px",
            color: "white",
            fontSize: "12px",
            lineHeight: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 700,

            "&::after": {
              content: "''",
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: "#f44336",
              animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
              zIndex: -1,
            },
          }}
        >
          2
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "calc(100% + 8px)",
            right: "0",
            width: "305px",
            backgroundColor: "#aad1fd",
            color: "#404040",
            padding: "16px",
            borderRadius: "12px 12px 0 12px",
            fontSize: "14px",
            lineHeight: "20px",

            "& p": {
              margin: "0",
            },
          }}
        >
          <p>Oi! Eu sou a sua assistente virtual.</p>
          <br />
          <p>Como posso te ajudar hoje? 😊</p>
        </Box>
      </Box>
    </Box>
  );
};
